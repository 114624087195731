@import "sesa-primeng-styles.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "/node_modules/primeflex/primeflex.css";

@font-face {
  font-family: 'Primeicons';
  src: url('/assets/fonts/primeicons.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: var(--surface-ground);
  font-family: var(--font-family);
  font-size: 11px;
}

html,
body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.bg-primary {
  background-color: #00336e;
  color: white;
}

.bg-secondary {
  background-color: #039BE5;
  color: white;
}

.bg-white {
  background-color: white;
  color: #00336e;
}

.bg-gris {
  background-color: #CBD6E1;
  color: white;
}

.border-secondary {
  border: solid 1px #039BE5;
}

.grecaptcha-badge {
  z-index: 5999 !important;
}

.p-divider.p-divider-horizontal {
  margin: 1rem 0 !important;
  padding: 0 1rem !important;
}

.text-link {
  text-decoration: none;
  color: var(--text-color-secondary);
}

.p-text-primary {
  color: #00336e;
}

.p-text-danger {
  color: #e24c4c;
}

.p-text-warning {
  color: #eec137;
}

.p-menubar {
  border: solid 0px transparent !important;
  border-bottom: solid 2px var(--primary-color) !important;
  border-radius: 0px !important;
  background-color: #fff !important;
}

.border-menu-seleccionado {
  border: solid 0px transparent;
  border-right: solid 5px #039BE5;
  color: #039BE5;
  font-weight: bold;
}

.p-text-success {
  color: #00D681
}

.mb-footer {
  margin-bottom: 75px;
}

.table {
  width: 100%;
  padding: 0rem 1rem;
  overflow-x: auto;
}

.tbody {
  display: flex;
  flex-direction: column;

  .tr {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: solid 0px transparent;
    border-bottom: solid 1px rgb(206, 212, 218);
  }

  .th,
  .td {
    display: flex;
    flex-direction: row;
    border: solid 0px transparent;
    border-collapse: collapse;
    padding: 1rem;
  }

  .th {
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
  }

  .td p,
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .td .first-col {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .th-1,
  .td-1 {
    width: 20%;
  }

  .th-2,
  .td-2 {
    width: 20%;
  }

  .th-3,
  .td-3 {
    width: 20%;
  }

  .th-4,
  .td-4 {
    width: 20%;
  }

  .th-5,
  .td-5 {
    width: 20%;
  }
}


.icon-active {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  padding: 0px;
  margin: 7px 10px 0px 0px;
  border-radius: 50%;
  background-color: #00D681;
}

.icon-inactive {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  padding: 0px;
  margin: 7px 10px 0px 0px;
  border-radius: 50%;
  background-color: #CBD6E1;
}

.list-tree {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  padding: 0px;
  border: solid 1px #00336e;
  border-collapse: collapse;

  .item-parent {
    display: flex;
    flex-direction: column;
    justify-content: left;
    border: solid 0px transparent;
    border-radius: 0px;
    background-color: transparent;
    width: 100%;
  }

  .title-parent {
    padding: 16px;
    background-color: #00336e;
    color: #fff;
  }

  .item-child {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    text-align: left;
    padding: 16px 24px 16px 16px;
    border: solid 0px #00336e;
    border-radius: 0px;
    border-collapse: collapse;
    padding: 16px;
  }

  .item-permissions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 16px 16px;
  }

  input[type="date"] {
    font-family: var(--font-family) !important;
    font-feature-settings: var(--font-feature-settings, normal) !important;
    font-size: 1rem !important;
    color: #495057;
    background: #ffffff !important;
    padding: 0.75rem 0.75rem !important;
    border: 1px solid #ced4da !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
    appearance: none !important;
    border-radius: 6px;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    /* Semiredondeado */
    border: 2px solid #CBD6E1;
    outline: none;
    background-color: #fff;
    cursor: pointer;
  }

  input[type="checkbox"]:checked {
    background-color: #039BE5;
    /* Color de fondo cuando está marcado */
    border-color: #039BE5;
    /* Color del borde cuando está marcado */
  }

  input[type="checkbox"]::before {
    content: '\2713';
    /* Código unicode del checkmark */
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    color: #fff;
    /* Color del checkmark cuando está marcado */
    font-size: 10px;
    border-radius: 5px;
    /* Semiredondeado */
    background-color: transparent;
    /* Color de fondo del indicador */
  }
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #039BE5 !important;
  background: #039BE5 !important;
}

.p-datatable-wrapper {
  overflow-x: auto !important;
}

.p-button-primary {
  border-color: #00336e !important;
  background: #00336e !important;
  color: #fff;
}

.p-button-primary-small {
  width: 25px !important;
  height: 25px !important;
  font-size: 12px !important;
  border-color: #00336e !important;
  background: #00336e !important;
  color: #fff;
}

.p-button-secondary {
  border-color: #039BE5 !important;
  background: #039BE5 !important;
}

.p-button-pagination {
  color: #00336e !important;
  border-color: transparent !important;
  background: transparent !important;
  cursor: pointer;
}

.p-button-pagination-active {
  color: #039BE5 !important;
  border-color: transparent !important;
  background: transparent !important;
  cursor: pointer;
}

.p-button-primary-outlined-small {
  width: 25px !important;
  height: 25px !important;
  font-size: 12px !important;
  color: #00336e !important;
  border-color: #00336e !important;
  background: transparent !important;
  text-align: center;
  padding: 0px;
}

.p-button-secondary-outlined-small {
  width: 25px !important;
  height: 25px !important;
  font-size: 12px !important;
  color: #039BE5 !important;
  border-color: #039BE5 !important;
  background: transparent !important;
  text-align: center;
  padding: 0px;
}

.p-button-danger-outlined-small {
  width: 25px !important;
  height: 25px !important;
  font-size: 12px !important;
  color: #e24c4c !important;
  border-color: #e24c4c !important;
  background: transparent !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

/*CARD STYLE: DESKTOP*/
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  padding: 0px;
}

.card-border {
  border: solid 1px #00336625
}

.card {
  position: relative;
  border: solid 0px transparent;
  border-radius: 0px 24px 24px 0px;
  box-shadow: 1px 2px 7px #00336620;
  background-color: #fff;
}

.card-primary {
  background-color: #003366;

  .card-title,
  .card-body {
    color: #fff !important;
  }
}

.card-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  margin: 0px;
  padding: 0px;
  min-height: 9rem;
}

.card-img {
  text-align: left;
  width: 9rem;
  margin: 0px;
  padding: 0px;

  img {
    width: 9rem;
    margin: 0px;
    padding: 0px;
  }
}

.card-title {
  width: 100%;
  margin: 0px;
  padding: 24px 24px 24px 24px;
  color: #003366;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.card-body {
  color: #003366;
  font-size: 18px;
  margin: 0px;
  padding: 0px 24px 24px 24px;
  min-height: 120px;
}

.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: 0px;
  padding: 0px 24px 24px 24px;
  min-height: 50px;
}

.sesa-bandera {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 10px;
  height: 100%;
  border: solid 0px transparent;
  background: linear-gradient(to bottom, #193964 0%, #193964 33%, #ECF3FF 33%, #ECF3FF 66%, #039BE5 66%, #039BE5 100%);
}

.card-bandera::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(to bottom, #193964 0%, #193964 33%, #ECF3FF 33%, #ECF3FF 66%, #039BE5 66%, #039BE5 100%);
}


.highcharts {
  position: relative;
  display: inline;
  width: 100%;
  height: 300px;
}

.container-submodule {
  padding: 0px 0px 100px 16px;
}

.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  text-align: justify;
}

/*END CARD STYLE: DESKTOP*/
@media screen and (max-width: 899px) {

  .th-1,
  .td-1 {
    width: 50%;
  }

  .th-2,
  .td-2 {
    width: 0%;
    display: none;
  }

  .th-3,
  .td-3 {
    width: 0%;
    display: none;
  }

  .th-4,
  .td-4 {
    width: 0%;
    display: none;
  }

  .th-5,
  .td-5 {
    width: 50%;
  }

  /*CARD STYLE: MOBILES*/
  .card-container {
    flex-direction: column;
  }

  .card-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    min-height: 125px;
  }

  .card-header,
  .card-body,
  .card-footer {
    min-height: auto;
    padding: 16px;
  }

  .card-img {
    text-align: left;
  }

  .card-title {
    text-align: left;
  }

  .card {
    width: calc(100% - 32px);
  }

  /*END CARD STYLE: MOBILES*/
  .sesa-bandera {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10px;
    border: solid 0px transparent;
    background: linear-gradient(to right, #193964 0%, #193964 33%, #ECF3FF 33%, #ECF3FF 66%, #039BE5 66%, #039BE5 100%) !important;
  }

  .container-submodule {
    padding: 0px 0px 100px 0px;
  }
}

.panel-trazabilidad {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin: 0px;
  padding: 0px;
  border: solid 1px #00336e;
  border-radius: 24px;
  background-color: #fff;
}

.panel-trazabilidad-cabecera-azul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 0px;
  border-bottom: solid 1px #00336e;
  border-radius: 24px 24px 0px 0px;
  background-color: #00336e;
  color: white;
}

.panel-trazabilidad-cabecera-solo-border-azul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 0px;
  border-bottom: solid 1px #00336e;
  border-radius: 24px 24px 0px 0px;
  background-color: transparent;
  color: #00336e;
}

.panel-trazabilidad-contenido {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 16px;
  margin: 0px;
  gap: 16px;
}
